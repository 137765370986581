<template>
	<div class="AlonePermission">
		<el-dialog :visible.sync="visible" center width="558px" @close="close">
			<div class="container pr00 br24 bgfff">
				<img
					class="bg"
					mode="widthFix"
					src="@/assets/components/my-alone-permission-bg.png"
				/>
				<img
					class="vip"
					src="@/assets/components/my-alone-permission-vip.png"
				/>
				<img
					class="close"
					@click="close"
					src="@/assets/public/close-popup.png"
				/>
				<div class="content pr00 plr32 font-33">
					<div class="tit fs22 fw-6 text-c">开通会员尊享VIP</div>
					<div class="title flex align-center fw-7 mt32 fs16">
						<span></span>
						尊享特权
					</div>
					<div class="fw-7 mt24">全部信息可查看可下载</div>
					<div class="flex justify-center">
						<button
							@click="jumpVip"
							class="button flex align-center justify-center"
						>
							开通VIP不限次数
						</button>
					</div>
					<div class="title flex align-center fw-7 mt32 fs16">
						<span></span>
						浏览查看
					</div>
					<div class="look flex flex-wrap align-center justify-between">
						<div class="flex align-center justify-between mt24 fw-5">
							<div class="left fs16 font-33">
								余额：{{ detailInfo.user_money }}
							</div>
							<div
								v-if="detailInfo.user_money >= detailInfo.user_money_pay"
								@click="(pay_method = 'balance'), popupFun(true)"
								class="right cursor"
							>
								余额支付{{ detailInfo.user_money_pay }}元
							</div>
							<!-- 		@click="handleJump"
								:data-url="'/pages/user/wallet/recharge/index'" -->
							<div
								v-else
								class="cursor"
								style="color: #4177dd"
								@click="handleJump"
							>
								余额不足，去充值
							</div>
						</div>
						<!-- <div class="flex align-center justify-between mt32">
							<div class="left fs16 font-33">积分：{{ detailInfo.score }}</div>
							<div
								v-if="detailInfo.score >= detailInfo.score_pay"
								@click="(pay_method = 'score'), popupFun(true)"
								class="right cursor"
							>
								积分支付{{ detailInfo.score_pay }}积分
							</div>
							<div v-else style="color: #4177dd">积分不足</div>
						</div> -->
					</div>
				</div>
			</div>
		</el-dialog>

		<el-dialog center width="520px" :visible.sync="innerVisible" append-to-body>
			<div class="inner-box font-33 text-c">
				<div class="title fs20 fw-7">{{ title }}</div>
				<div class="con fs18">{{ content }}</div>
				<div class="footer flex justify-between align-center">
					<el-button @click="myConfirmCancel">否</el-button>
					<el-button @click="myConfirmConfirm" :loading="loading">是</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>
<script>
export default {
	name: "AlonePermission",
	props: {
		// 详情
		detail: {
			required: true,
		},
		// 信息
		detailInfo: {
			required: true,
		},
	},
	data() {
		return {
			visible: false,
			innerVisible: false,

			loading: false, // 是否支付中
			content: "",
			title: "",
			pay_method: "", // balance 余额; score 积分;
		};
	},
	methods: {
		// 支付
		myConfirmCancel() {
			this.innerVisible = false;
		},
		// 支付确认弹窗
		myConfirmConfirm() {
			let { detail, pay_method, detailInfo } = this;

			if (this.loading) {
				this.$message.info("支付中");
				return false;
			}

			this.loading = true;
			this.$API
				.jumpDetailPay({
					pay_method,
					article_id: detail.id,
					order_money:
						pay_method == "balance"
							? detailInfo.user_money_pay
							: detailInfo.score_pay,
				})
				.then((res) => {
					console.log("支付成功 ress", res);
					if (res.code == 1) {
						this.confirm();
					} else {
						this.loading = false;
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
		//跳转充值页面
		handleJump() {
			this.close();

			this.$router.push({
				name: "Wallet",
				query: { id: 5 },
			});
		},
		// 跳转开通会员页面
		jumpVip() {
			this.close();

			this.$router.push({
				path: "vip",
			});
		},
		confirm() {
			this.popupFun(false);
			this.loading = false;
			this.close();
			this.$emit("confirm", {});
		},
		cancel() {
			this.close();
			this.$emit("cancel", {});
		},
		open() {
			this.visible = true;
		},
		close() {
			this.content = "";
			this.title = "";
			this.pay_method = "";

			if (this.visible) {
				this.visible = false;
			}
		},
		/**
		 * ! 是否显示弹窗
		 * @param {sign} sign 显示隐藏 true 显示 false 隐藏
		 */
		popupFun(sign) {
			let { pay_method, detailInfo } = this;
			let { user_money_pay, score_pay } = detailInfo;

			if (sign) {
				if (pay_method == "balance") {
					this.content =
						"您确认支付" + user_money_pay + "元进行查看政策信息吗？";
					this.title = "余额支付";
				}

				if (pay_method == "score") {
					this.content = "您确认支付" + score_pay + "积分进行查看政策信息吗？";
					this.title = "积分支付";
				}

				this.innerVisible = true;
			} else {
				this.innerVisible = false;
			}
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.el-dialog__wrapper {
		> div {
			background: initial;
			box-shadow: none;
		}
	}
	.el-dialog__header {
		display: none;
	}

	.el-dialog__body {
		padding: 0;
		display: flex;
		justify-content: center;
	}

	.inner-box {
		padding: 40px 48px;

		.title {
			padding-top: 24px;
		}

		.con {
			margin-top: 48px;
		}

		.footer {
			margin-top: 80px;

			button {
				width: 200px;
				height: 48px;
				background: #f8f8f8;
				opacity: 1;
				border-radius: 24px;
				font-size: 16px;
				font-weight: 400;
				color: #333333;
				border: none;

				&:last-child {
					background: #ff8300;
					color: #ffffff;
				}
			}
		}
	}
}
.AlonePermission {
	.container {
		width: 558px;
		overflow: hidden;
		padding-bottom: 48px;

		.bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
		}

		.vip {
			position: absolute;
			left: 0;
			top: 0;
			width: 140px;
			height: 40px;
		}

		.close {
			position: absolute;
			right: 32px;
			top: 24px;
			width: 18px;
			height: 18px;
			cursor: pointer;
		}

		.content {
			.tit {
				margin-top: 150px;
			}

			.title {
				span {
					width: 6px;
					height: 28px;
					background: linear-gradient(132deg, #f5d991 0%, #e5b96d 100%);
					border-radius: 4px;
					margin-right: 12px;
				}
			}

			.button {
				margin-top: 40px;
				width: 280px;
				height: 48px;
				border: 2px solid #e4b871;
				background: linear-gradient(132deg, #f5d991 0%, #e5b96d 100%);
				border-radius: 44px;
				font-size: 16px;
				font-weight: 700;
				color: #333333;
			}

			.look {
				> .flex {
					border: 1px solid #e4b871;
					border-radius: 100px;
					width: 542px;
					padding: 14px 40px;
					padding-right: 48px;

					.right {
						color: #a5772c;
					}
				}
			}
		}
	}
}
</style>
